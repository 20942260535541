import React from 'react'
import {
  BaseImage,
  Box,
  BoxDesc,
  BoxImage,
  BoxTitle,
  Container,
  Wrapper,
} from './style'
import Image from '../image'
import { Link } from 'gatsby'

export const ImageBlock = () => {
  return (
    <Wrapper>
      <Container>
        <BaseImage>
          <Link to={'/accessory'}>
            <Image filename="photo/kamakura/001.jpg" alt="天然石・パワーストーンのアクセサリー" />
          </Link>
        </BaseImage>

        <Box index={1}>
          <Link to={'/bracelet-list'}>
            <BoxImage>
              <Image filename="photo/kimono_2.jpg" alt="天然石・パワーストーンのブレスレット" />
            </BoxImage>
            <BoxTitle>ブレスレット</BoxTitle>
            <BoxDesc>
              厳選した品質の良いビーズを使用しています。
              天然石の魅力を楽しめるブレスレット。
            </BoxDesc>
          </Link>
        </Box>

        <Box index={2}>
          <Link to={'/necklace-list'}>
            <BoxImage>
              <Image filename="photo/necklace.jpg" alt="天然石・パワーストーンのネックレス" />
            </BoxImage>
            <BoxTitle>ネックレス</BoxTitle>
            <BoxDesc>天然石の魅力を引き立てるシンプルなデザイン。</BoxDesc>
          </Link>
        </Box>
      </Container>
    </Wrapper>
  )
}
