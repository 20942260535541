import React from 'react'
import Image from '../../image'
import { EnLabel, Line, Label, Wrapper } from './styles'

const TitleTop = ({ en, label, isLine = true }) => {
  return (
    <Wrapper>
      <EnLabel>{en}</EnLabel>
      <Line>{isLine && <Image filename="title/line_red.png" />}</Line>
      <Label>{label}</Label>
    </Wrapper>
  )
}

export default TitleTop
