import React from 'react'
import {
  Wrapper,
  Head,
  LineWrapper,
  Logo,
  TextWrapper,
  VideoWrapper,
} from './style'
import Image from '../image'
import HeroMov from '../../images/video/hero-mov.mp4'
import HeroMovSp from '../../images/video/hero-mov_sp.mp4'
import ScrollLine from '../ScrollLine'
import { isMobile } from 'react-device-detect';

export const Hero = () => {
  return (
    <Wrapper>
      <Head>
        <VideoWrapper>
          <video muted playsInline autoPlay loop>
            {isMobile ? <source src={HeroMovSp} type="video/mp4" /> : <source src={HeroMov} type="video/mp4" />}
          </video>
        </VideoWrapper>
        <Logo>
          <Image filename="logo/logo_new_w.png" alt="ご縁をつなぐ御守り〈高天原〉天然石アクセサリー" />
        </Logo>
        <TextWrapper>
          <Image filename="title/goen.png" alt="ご縁をつなぐ御守り" />
        </TextWrapper>
      </Head>

      <LineWrapper>
        <ScrollLine />
      </LineWrapper>
    </Wrapper>
  )
}
