import styled from '@emotion/styled'

export const Container = styled.div`
  opacity: ${props => (props.opacity ? props.opacity : 0.6)};
  background-color: #1c1c1c;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
