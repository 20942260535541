import React from 'react'
import TitleTop from '../../../components/Title/TitleTop'
import { Text, Container, Wrapper } from './styles'
import KanjiTitle from '../../../components/Title/KanjiTitle'
import TextLink from '../../../components/TextLink'
import CategoryRow from '../../../components/CategoryRow'

const Accessory = () => {
  return (
    <Wrapper>
      <Container>
        <TitleTop en="ACCESSORY" label="高天原のアクセサリー" />
        <KanjiTitle label="雅" />
        <Text>
          上品で洗練された美しさ。 <br />
          厳選した上質な天然石を使用したシンプルなデザイン。
          和をテーマにしたオリジナルデザイン。
        </Text>
        <CategoryRow />
        <TextLink label="アクセサリーを見る" link="/accessory" />
      </Container>
    </Wrapper>
  )
}

export default Accessory
