import React from 'react'
import { LinkWrapper, Label, Wrapper } from './style'

const TextLink = ({ label, link }) => {
  return (
    <Wrapper>
      <LinkWrapper to={link}>
        <Label>{label}</Label>
      </LinkWrapper>
    </Wrapper>
  )
}

export default TextLink
