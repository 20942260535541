import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 70px;
  }
`
export const EnLabel = styled.div`
  font-family: linotype-didot, serif;
  font-size: 18px;
  padding-left: 2px;
  font-weight: normal;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
`

export const Label = styled.h2`
  font-size: 24px;
  font-weight: 700;
  padding-left: 2px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 40px;
  }
`

export const Line = styled.div`
  width: 200px;
  min-height: 8px;

  @media (min-width: ${breakpoints.m}px) {
    width: 250px;
  }
`
