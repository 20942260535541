import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 80px;
  }
`

export const Label = styled.div`
  font-size: 28px;
  font-weight: 700;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 55px;
  }
`
