import styled from '@emotion/styled'
import bg from '../../../images/bg/bg_miyabi.jpg'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  background-image: url(${bg});
  background-size: cover;
  padding: 24px 12px 40px;
`

export const Container = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`

export const Text = styled.div`
  font-size: 15px;
  color: #707070;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 50px;
  }
`

export const Photo = styled.div`
  margin-bottom: 16px;
`
