import React from 'react'
import 'swiper/swiper-bundle.min.css'
import '../../../styles/carousel.css'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../../image'
import Scale from '../../Scale'
import { Button } from '../../Button'
import { _SwiperContainer, ButtonWrap, Content, Title, Wrapper } from './style'
import { Link } from 'gatsby'

SwiperCore.use([Navigation, Pagination, Autoplay])

const slideData = [
  {
    title: '空海のブレス',
    filename: 'photo/carousel/kukai.jpg',
    link: 'https://takamagahara-ku-kai.studio.site/',
    label: '購入する',
  },
  {
    title: '在り方お守り',
    filename: 'photo/omamori.jpg',
    link: 'https://takamagahara-omamori.studio.site/',
    label: '購入する',
  },
]

export const CarouselEyeCatch = () => {
  return (
    <Wrapper>
      <Swiper navigation loop autoplay>
        {slideData.map(({ title, filename, link, label }, index) => (
          <SwiperSlide key={`${title}-${index}`}>
            <_SwiperContainer>
              <Image filename={filename} />
              <Scale opacity={0.3} />
              <Content>
                <Title>{title}</Title>
                <ButtonWrap>
                  <Link to={link}>
                    <Button label={label} />
                  </Link>
                </ButtonWrap>
              </Content>
            </_SwiperContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}
