import React, { useContext } from 'react'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import { ImageWrapper, Item, Name, Price, Tail, Wrapper } from './style'
import { graphql, Link, useStaticQuery } from 'gatsby'
import StoreContext from '../../../context/StoreContext'
import CategoryTitle from '../../Title/CategoryTitle'
import { Button } from '../../Button'
import { Img } from '../../../utils/styles'

SwiperCore.use([Navigation, Pagination])

export const Collection = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: { fields: [createdAt], order: DESC }
          filter: { tags: { in: "Pickup" } }
        ) {
          edges {
            node {
              tags
              id
              title
              handle
              shopifyId
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  const {
    store: { checkout },
  } = useContext(StoreContext)

  const getPrice = price =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
      style: 'currency',
    }).format(parseFloat(price ? price : 0))

  return (
    <Wrapper>
      {/*<CategoryTitle en="COLLECTION" label="" />*/}
      <Swiper navigation loop>
        {allShopifyProduct.edges ? (
          allShopifyProduct.edges.map(
            ({
              node: {
                id,
                handle,
                shopifyId,
                title,
                images,
                variants: [firstVariant],
              },
            }) => (
              <SwiperSlide>
                <Item key={id}>
                  <Link to={`/product/${shopifyId}/`}>
                    <ImageWrapper>
                      {images[0] && images[0].localFile && (
                        <Img
                          fluid={images[0].localFile.childImageSharp.fluid}
                          alt={handle}
                        />
                      )}
                    </ImageWrapper>
                    <Tail>
                      <Name>{title}</Name>
                      {/*<Price>{getPrice(firstVariant.price)}</Price>*/}
                    </Tail>
                    <Button label="購入する" />
                  </Link>
                </Item>
              </SwiperSlide>
            )
          )
        ) : (
          <p>no item</p>
        )}
      </Swiper>
    </Wrapper>
  )
}
