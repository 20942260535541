import React from 'react'
import { Container, Label } from './styles'

const KanjiTitle = ({ label }) => {
  return (
    <Container>
      <Label>『{label}』</Label>
    </Container>
  )
}

export default KanjiTitle
