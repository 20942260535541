import React from 'react'
import styled from '@emotion/styled'
import color from '../../styles/color'

export const Button = ({ width, color, type, label }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Label = styled.div`
  color: ${color.WHITE};
  padding: 10px 12px;
  width: 160px;
  text-align: center;
  background-color: ${color.THEMA};
`
