import React from 'react'
import styled from '@emotion/styled'
import color from '../../styles/color'

export const EmbedTw = () => {
  return (
    <Container>
      <a
        className="twitter-timeline"
        data-width="350"
        data-height="400"
        data-theme="dark"
        href="https://twitter.com/takamagahara369?ref_src=twsrc%5Etfw"
      >
        Tweets by takamagahara369
      </a>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      />
    </Container>
  )
}

export const Container = styled.div`
  background-color: ${color.BLACK};
  display: flex;
  justify-content: center;
`
