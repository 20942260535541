import styled from '@emotion/styled'
import { Link } from 'gatsby'
import colors from '../../styles/color'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: #1c1c1c;
`

export const Label = styled.div`
  display: inline;
  position: relative;
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 2px;
  border-bottom: 2px solid ${colors.RED};

  @media (min-width: ${breakpoints.m}px) {
    font-size: 26px;
    padding-bottom: 6px;
    border-width: 3px;
  }
`
export const Circle = styled.div`
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 20px;
  height: 20px;
`
