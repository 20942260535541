import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'
import colors from '../../../styles/color'

export const Wrapper = styled.div`
  padding: 50px 15px;
  background-color: ${colors.WHITE_GRAY};

  @media (min-width: ${breakpoints.m}px) {
    padding: 50px 20px;
  }
`

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`

export const ImageWrapper = styled.div`
  width: 200px;
  margin: 0 auto 4px;

  @media (min-width: ${breakpoints.m}px) {
    width: 400px;
  }
`

export const Tail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  padding: 0 4px;
`

export const Name = styled.div`
  color: ${colors.BLACK};
  font-size: 15px;
  text-align: center;
  min-height: 42px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 20px;
  }
`

export const Price = styled.div`
  font-size: 20px;
  color: ${colors.BLACK};
`
