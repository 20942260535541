import React from 'react'
import { Link } from 'gatsby'
import Image from '../image'
import {
  CategoryName,
  Desc,
  Wrapper,
  Tail,
  ImageWrapper,
  Row,
  Card,
  RowItem,
} from './styles'

const LIST = [
  {
    path: '/bracelet-list',
    filename: 'photo/bracelet.jpg',
    categoryName: '一連ブレスレット',
    altTitle: '天然石・パワーストーンの一連ブレスレット',
    desc:
      '厳選した品質の良いビーズを使用しています。天然石の魅力を楽しめるブレスレット。',
  },
  {
    path: '/designbracelet-list',
    filename: 'photo/design-bracelet.jpg',
    categoryName: 'デザインブレスレット',
    altTitle: '天然石・パワーストーンのデザインブレスレット',
    desc:
      '「神は細部に宿る」1つ1つのパーツに拘りました。和をイメージしたデザイン。',
  },
  {
    path: '/necklace-list',
    filename: 'photo/necklace.jpg',
    categoryName: 'ネックレス',
    altTitle: '天然石・パワーストーンのネックレス',
    desc: '天然石の魅力を引き立てるシンプルなデザイン。',
  },
  {
    path: '/stone-list',
    filename: 'photo/stone.jpg',
    categoryName: '原石',
    altTitle: '天然石・パワーストーンの原石',
    desc: '自然が生み出した芸術品。空間やアクセサリーの浄化にもお勧め。',
  },
  {
    path: '/item-list',
    filename: 'photo/omamori.jpg',
    categoryName: '雑貨',
    altTitle: '天然石・パワーストーンの雑貨',
    desc: '高天原のコンセプトを雑貨の形で表現したオリジナル商品。',
  },
]

const CategoryRow = () => {
  return (
    <Wrapper>
      <Row>
        {LIST.map(({ path, filename, categoryName, altTitle, desc }) => (
          <RowItem>
            <Link to={path}>
              <Card>
                <ImageWrapper>
                  <Image filename={filename} alt={altTitle} />
                </ImageWrapper>
                <Tail>
                  <CategoryName>{categoryName}</CategoryName>
                  <Desc>{desc}</Desc>
                </Tail>
              </Card>
            </Link>
          </RowItem>
        ))}
        {/*二行目の並びを整えるため*/}
        <RowItem />
      </Row>
    </Wrapper>
  )
}

export default CategoryRow
