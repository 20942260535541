import React from 'react'
import styled from '@emotion/styled'
import colors from '../../styles/color'
import CategoryTitle from '../Title/CategoryTitle'
import { breakpoints } from '../../utils/styles'

import loadable from '@loadable/component'
const LazyComponent = loadable(() => import('./LazyComponent'));

export const PvContainer = () => {
  return (
    <Wrapper>
      <CategoryTitle label="コンセプトムービー" en="movie" color="white" />
      <Container>
        <LazyComponent></LazyComponent>
      </Container>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  padding: 50px 0;
  background-color: ${colors.BLACK};

  @media (min-width: ${breakpoints.l}px) {
    padding: 80px 0;
  }
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;

  @media (min-width: ${breakpoints.l}px) {
    padding-top: 40%;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 20px * 2);
    max-width: 1000px;
    max-height: 600px;
    height: 100%;
  }
`
