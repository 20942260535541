import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import TitleTop from '../../../components/Title/TitleTop'
import TextLink from '../../../components/TextLink'
import { breakpoints } from '../../../utils/styles'
import color from '../../../styles/color'

export const News = () => {
  const { allShopifyArticle } = useStaticQuery(
    graphql`
      query {
        allShopifyArticle(limit: 3) {
          edges {
            node {
              id
              contentHtml
              title
              handle
              publishedAt(formatString: "YYYY.M.D")
            }
          }
        }
      }
    `
  )

  const item = allShopifyArticle.edges

  return (
    <Container>
      <TitleTop label="お知らせ" en="news" />
      {item &&
        item.map(({ node: { id, handle, title, publishedAt } }) => (
          <Link to={`/news/${handle}`} key={id}>
            <List>
              <Day>{publishedAt}</Day>
              <Title>{title}</Title>
            </List>
          </Link>
        ))}

      <TextLink link="news" label="お知らせ一覧" />
    </Container>
  )
}

const Container = styled.div`
  padding: 15px;

  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`
const List = styled.div`
  margin-bottom: 10px;
`

const Title = styled.h3`
  font-size: 15px;
  font-weight: normal;
  color: ${color.BLACK};
`

const Day = styled.div`
  font-size: 12px;
  color: ${color.BLACK};
`
