import styled from '@emotion/styled'
import colors from '../../styles/color'
import { breakpoints } from '../../utils/styles'
import bg from '../../images/bg/bg_washi.jpg'

export const Wrapper = styled.div`
  padding: 50px 0;
  background-image: url(${bg});
`

export const Container = styled.div`
  position: relative;
  width: 70%;
  max-width: 1000px;
  margin: 0 auto;
`
export const BaseImage = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.m}px) {
    height: 770px;
    overflow: hidden;
  }
`

export const Box = styled.div`
  display: none;
  position: absolute;
  width: 260px;
  top: ${props => (props.index === 1 ? '440px' : '60px')};
  ${props => (props.index === 1 ? 'left: 100px' : 'right: 100px;')};
  background-color: ${colors.WHITE_GRAY};
  padding: 24px;
  border-radius: 2px;
  transition: all 400ms;

  //SPでは非表示
  @media (min-width: ${breakpoints.m}px) {
    display: block;
  }

  :hover {
    transform: scale(1.02);
    transition: all 400ms;
  }
`

export const BoxImage = styled.div`
  margin-bottom: 8px;
  img {
    border-radius: 2px;
  }
`
export const BoxTitle = styled.div`
  display: inline-block;
  line-height: 1.2;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
  color: ${colors.BLACK};
  border-bottom: 2px solid ${colors.RED};
`
export const BoxDesc = styled.div`
  font-size: 14px;
  color: ${colors.BLACK};
`
