import React from 'react'
import { Line, LineItem, Text, Wrapper } from './style'

const ScrollLine = () => {
  return (
    <Wrapper>
      <Line>
        <LineItem></LineItem>
      </Line>
      <Text>scroll</Text>
    </Wrapper>
  )
}

export default ScrollLine
