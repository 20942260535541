import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 60px);
`

export const Head = styled.div`
  padding: 45px 0;
  height: calc(100vh - 60px);
  background-color: rgb(28 28 28 / 0.6);
`

export const VideoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;

  video {
    width: 100%;
  }

  @media (max-width: ${breakpoints.s}px) {
    display: flex;
    justify-content: center;

    video {
      width: auto;
      height: 100vh;
    }
  }
`

export const Logo = styled.div`
  margin: 0 auto 45px;
  width: 80px;
  
  @media (min-width: ${breakpoints.m}px) {
    margin: 40px auto 90px;
    width: 100px;
  }
`

export const TextWrapper = styled.div`
  margin: 0 auto;
  width: 50px;

  @media (min-width: ${breakpoints.m}px) {
    width: 75px;
  }
`

export const LineWrapper = styled.div`
  position: absolute;
  bottom: 45px;
  left: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`
