import styled from '@emotion/styled'
import colors from '../../styles/color'
import { breakpoints } from '../../utils/styles'
import line from '../../images/title/line_gold.png'

export const Wrap = styled.div`
  background-color: ${colors.BLACK};
`
export const Container = styled.div`
  padding: 24px 10px 24px;
  margin: 0 auto;
  max-width: 1200px;
`

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Card = styled.div`
  font-size: 30px;
  color: ${colors.BLACK};
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 24px;
    padding: 14px;
    width: 48%;
  }
`

export const Thumbnail = styled.div`
  width: 100%;
  margin-bottom: 10px;
  img {
    border-radius: 4px;
  }
`

export const Text = styled.h3`
  font-size: 15px;
  font-weight: 700;
  color: ${colors.WHITE};
  text-align: center;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`

export const Label = styled.div`
  font-size: 12px;
  color: ${colors.WHITE};

  @media (min-width: ${breakpoints.m}px) {
    font-size: 18px;
    line-height: 1.6;
  }
`
export const Line = styled.div`
  width: 60px;
  height: 3px;
  margin: 0 auto 4px;
  background-image: url(${line});
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media (min-width: ${breakpoints.m}px) {
    margin: 0 auto 14px;
  }
`
