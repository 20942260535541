import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import color from '../../styles/color'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const lineAnimation = keyframes`
  from {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(120px);
  }
`

export const Line = styled.div`
  position: relative;
  overflow: hidden;
  height: 120px;
  width: 2px;
  margin-right: 1px;
  background-color: ${color.GRAY};
`

export const LineItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  height: 15px;
  width: 2px;
  background-color: ${color.WHITE};
  animation: ${lineAnimation} 2.5s linear;
  animation-iteration-count: infinite;
`

export const Text = styled.div`
  font-size: 12px;
  color: ${color.WHITE};
  text-align: right;
  writing-mode: vertical-rl;
`
