import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 50px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow: scroll;
  overflow-scrolling: touch;

  @media (min-width: ${breakpoints.m}px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

export const RowItem = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    display: flex;
    flex-wrap: wrap;
    width: 30%;
    margin-bottom: 40px;
    //margin-right: 20px;
  }
`

export const Card = styled.div`
  width: 210px;
  margin-right: 8px;

  @media (min-width: ${breakpoints.m}px) {
    width: 100%;
    margin-right: 0;
  }
`

export const ImageWrapper = styled.div`
  margin-bottom: 12px;

  img {
    border-radius: 4px;
  }
`

export const Tail = styled.div`
  color: #707070;
`

export const CategoryName = styled.h3`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 4px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 24px;
  }
`

export const Desc = styled.div`
  font-size: 12px;

  @media (min-width: ${breakpoints.m}px) {
    font-size: 18px;
  }
`
