import React from 'react'
import SEO from '~/components/seo'
import Accessory from '../templates/Top/Accessory'
import { Hero } from '../components/Hero'
import styled from '@emotion/styled'
import { ContentsList } from '../components/ContentsList'
import { CarouselEyeCatch } from '../components/Carousel/EyeCatch'
import { Collection } from '../components/Carousel/Collection'
import { ImageBlock } from '../components/ImageBlock'
import { PvContainer } from '../components/PvContainer'
import { EmbedTw } from '../components/EmbedTw'
import { News } from '../templates/Top/News'

export const Container = styled.div`
  margin: 0 auto;
`

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`高天原`, `天然石アクセサリー`, `パワーストーン`, `天然石 ブレスレット`, `天然石 ネックレス`]} />
    <Hero />
    <News />
    <Collection />

    <Container>
      <ImageBlock />
      <PvContainer />
      <CarouselEyeCatch />
      <Accessory />
      <ContentsList />
      <EmbedTw />
    </Container>
  </>
)

export default IndexPage
