import React from 'react'
import {
  Card,
  CardList,
  Container,
  Label,
  Line,
  Text,
  Thumbnail,
  Wrap,
} from './style'
import Image from '../image'
import { Link } from 'gatsby'
import { truncate } from '../../logics'
import CategoryTitle from '../Title/CategoryTitle'

const LIST = [
  {
    text: 'ブランドストーリー',
    filename: 'photo/site-image.jpg',
    path: '/brandstory',
    label:
      '本当に大切な事は目に見えない この想いを形にしたいと立ち上げたブランドです。ブランド名の由来は古事記に於いて、天照大神をはじめとする神々が住んでいたとされる天上の世界を指す「高天原」からお借りしました。',
  },
  {
    text: 'こだわり',
    filename: 'photo/shiraito-taki.jpg',
    path: '/feature',
    label:
      'アクセサリーを永く使っていただける様に品質にこだわっています。 天然石のもつ本来の魅力を最大限に活かす為に「浄化」と「自然のエネルギー」を取り入れています。',
  },
  {
    text: '身に付け方',
    filename: 'photo/how-to-wear.jpg',
    path: '/howtowear',
    label:
      '高天原のアクセサリーのお勧めの使い方をご紹介します。今必要だと感じたら手に取ってみて下さい。ある物事と向き合う時、支えになってくれることでしょう。',
  },
  {
    text: '選び方',
    filename: 'choice-bracelet.jpg',
    path: '/choice',
    label:
      '自分の直感を信じてみて下さい。 ご縁のある石が不思議と分かります。 また、ストーンリーディング（石選び）も出来ますので その際はお問い合わせ下さい。',
  },
]

export const ContentsList = () => {
  return (
    <Wrap>
      <Container>
        <CategoryTitle en="concept" label="コンセプト" color="white" />
        <CardList>
          {LIST.map(({ text, filename, path, label }) => (
            <Card>
              <Link to={path}>
                <Thumbnail>
                  <Image filename={filename} alt={text} />
                </Thumbnail>
                <Text>{text}</Text>
                <Line />
                <Label>{truncate(label, 50)}</Label>
              </Link>
            </Card>
          ))}
        </CardList>
      </Container>
    </Wrap>
  )
}
