import styled from '@emotion/styled'
import { breakpoints } from '../../../utils/styles'
import color from '../../../styles/color'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  @media (min-width: ${breakpoints.m}px) {
    height: 80vh;
  }
`

// ライブラリの提供するコンポーネントと区別するため_
export const _SwiperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  video {
    width: 150%;
  }

  @media (min-width: ${breakpoints.m}px) {
    height: 80vh;
  }
`

export const ButtonWrap = styled.div`
  //position: absolute;
`

export const Content = styled.div`
  width: 70%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled.div`
  color: ${color.WHITE};
  font-size: 26px;
  margin-bottom: 12px;
  @media (min-width: ${breakpoints.m}px) {
    font-size: 50px;
  }
`
